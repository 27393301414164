import React from "react";
import { varshasri, venkat, rupavarshini, suresh, kanakarsu, esther, rajappan, sarathi } from "../../assets/images";
import { Container } from "../../components/Container";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


const testimonials = [
  {
    id: 1,
    text: "We are thrilled to announce that one of our talented team members, who began their journey as a FlexPLM developer at YaazhTech in 2020, later transitioned to a Java Fullstack role within the company. Through dedication and the hands-on experience gained at YaazhTech, they have now achieved the prestigious position of Technical Architect at ITC Infotech. This accomplishment reflects the depth of opportunities and mentorship provided at YaazhTech, preparing our developers for success in their careers.",   author: "Venkatesan Vadivel",   role: "Technical Architect",
    company: "ITC Infotech",
    profileImage: venkat,
    backgroundColor: "#7eabd8",
  },
  {
    id: 2,
    text: "We are excited to announce that one of our senior developers, who started as a Java intern trainee in 2016, worked on multiple client projects at YaazhTech, adding significant value to our clients. Through dedication and expertise, they rose to the role of tech lead at YaazhTech, and in 2021, they secured a prestigious position as a Senior Software Engineer at Virtusa. This accomplishment highlights the growth opportunities at YaazhTech and the readiness of our team to excel in top organizations.",
    author: "Suresh Murugan",
    role: "Senior Software Engineer",
    company: "Virtusa",
    profileImage: suresh,
    backgroundColor: "#d3d925",
  },
  {
    id: 2,
    text: "We are thrilled to announce that one of our trainees, who began their journey with Yaazhtech in 2020, has successfully advanced through our rigorous training programs and projects. They have now secured a prominent role as a Senior Software Engineer at Softura, demonstrating the strength of our curriculum and the exceptional preparedness of our candidates for leadership positions in the industry.",
    author: "Varshasri",   
    role: "Senior Software Engineer",
    company: "Softura",
    profileImage: varshasri,
    backgroundColor: "#df51bb",
  },
  {
    id: 3,
    text: "We are delighted to announce that a trainee who began their journey with Yaazhtech in 2019 has successfully completed our comprehensive development program and key projects, earning a well-deserved role as a Senior Software Engineer at E-Team(Accenture). This accomplishment highlights the effectiveness of our training in preparing candidates for impactful careers in top global companies.",
    author: "Rupavarshini",
    role: "Senior Software Engineer",
    company: "Eteam",
    profileImage: rupavarshini,
    backgroundColor: "#51cfdf",
  },
  {
    id: 4,
    text: "We are pleased to announce the remarkable journey of one of our team members at Yaazhtech. Starting their career as a Software Engineer at Source HOV Pvt Ltd from 2013 to 2016, they joined us in 2016 as a Full Stack Developer. Through dedication and continuous growth, they have now risen to the esteemed position of Technical Architect. This achievement highlights the opportunities for career development within Yaazhtech, empowering individuals to advance to leadership roles in the tech industry.",
    author: "Kanakarasu Manickam ",
    role: "Technical Architect",
    company: "Yaazhtech",
    profileImage: kanakarsu,
    backgroundColor: "#51cfdf",
  },
  {
    id: 5,
    text: "We are pleased to announce the remarkable journey of one of our team members at Yaazhtech. Starting their career as a System Admin Engineer at HP Pvt Ltd from 2015 to 2021, they joined us in 2023 as a Full Stack Developer. Through dedication and continuous growth, they have now risen to the esteemed position of Technical Architect. This achievement highlights the opportunities for career development within Yaazhtech, empowering individuals to advance to leadership positions in the tech industry.",
    author: "Sarathi",
    role: "Technical Architect",
    company: "Yaazhtech",
    profileImage: sarathi,
    backgroundColor: "#51cfdf",
  },
  // {
  //   id: 6,
  //   text: "We are delighted to announce that a trainee who began their journey with Yaazhtech in 2019 has successfully completed our comprehensive development program and key projects, earning a well-deserved role as a Senior Software Engineer at Yaazhtech. This accomplishment highlights the effectiveness of our training in preparing candidates for impactful careers in top global companies.",
  //   author: "Esther Jentia",
  //   role: "TechLead",
  //   company: "Yaazhtech",
  //   profileImage: esther,
  //   backgroundColor: "#51cfdf",
  // },
  {
    id: 7,
    text: "We are delighted to announce that a trainee who began their journey with Yaazhtech in 2021 has successfully completed our comprehensive development program and key projects, earning a well-deserved role as a Full Stack Developer at Yaazhtech. This accomplishment highlights the effectiveness of our training in preparing candidates for impactful careers in top global companies.",
    author: "Rajappan",
    role: "Full Stack Developer",
    company: "Yaazhtech",
    profileImage: rajappan,
    backgroundColor: "#51cfdf",
  },
];

const TestimonialSlider = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const goToPrevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  React.useEffect(() => {
    const interval = setInterval(goToNextSlide, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 mb-6">
      Our Successful Developers
      </h2>
      <div className="relative max-w-[1200px] mx-auto h-auto flex flex-col md:flex-row items-center justify-between overflow-hidden px-4 md:px-20 space-y-6 md:space-y-0">
        {/* Arrows for navigation */}
        <button
          onClick={goToPrevSlide}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white p-2 bg-gray-700 rounded-full z-10 md:left-8"
        >
          <FaArrowLeft size={24} />
        </button>

        {/* Testimonial Content */}
        <div
          key={testimonials[currentSlide].id}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          style={{
            backgroundColor: testimonials[currentSlide].backgroundColor,
          }}
          className="w-full flex flex-col md:flex-row items-center justify-between p-4 md:p-8 rounded-[20px] shadow-lg "
        >
          {/* Text Section */}
          <div className="md:w-1/2 flex flex-col items-center md:items-start space-y-4 md:space-y-6 text-center md:text-left">
            <h2 className="text-xl md:text-4xl font-bold">
              {testimonials[currentSlide].author}
            </h2>
            <h3 className="text-lg md:text-xl font-semibold">
              {testimonials[currentSlide].role}{", "}
              <span className="text-base md:text-lg">
                {testimonials[currentSlide].company}
              </span>
            </h3>
            <p className="text-sm md:text-lg">
              <span className="p-1 text-gray-700">" {testimonials[currentSlide].text} "</span>
            </p>
          </div>

          {/* Image Section */}
          <div className="md:w-1/2 flex justify-center md:justify-end mt-4 md:mt-0">
            <img
              src={testimonials[currentSlide].profileImage}
              alt={testimonials[currentSlide].author}
              className="w-[160px] h-[240px] md:w-[245px] md:h-[342px] rounded-[10px] object-cover"
            />
          </div>
        </div>

        <button
          onClick={goToNextSlide}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white p-2 bg-gray-700 rounded-full z-10 md:right-8"
        >
          <FaArrowRight size={24} />
        </button>

        {/* Navigation Dots */}
        <div className="absolute bottom-4 md:bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                currentSlide === index ? "bg-white" : "bg-gray-400"
              }`}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default TestimonialSlider;

