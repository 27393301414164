import React, { useState } from 'react';
import LeadershipCard from './LeadershipCard';
import LeadershipPopup from './LeadershipPopup';
import { esther, removebgRajappan, rupavarshini, varshasri, venkat, rajappan, removebgVenkat, removebgEsther, removebgVarshasri, removebgRupavarshini, removebgSoundharya, removebgKanakarasu, removebgRekha, removebgSarathi } from '../../../assets/images';
import { Container } from '../../../components/Container';

const ExecutiveTeam = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to open the popup
  const handleReadMore = (member) => {
    setSelectedMember(member);
  };

  // Function to close the popup
  const closePopup = () => {
    setSelectedMember(null);
  };

  const teamMembers = [
    {
      name: 'Venkatesan Vadivel',
      position: 'VP of Product (Product Manager)',
      imageUrl: removebgVenkat,
      color: '#a7dff1',
      about: 'Venkatesan is the VP of Product and brings a wealth of experience in product management.',
      linkedin: 'https://www.linkedin.com/in/venkatesan-vadivel/'
    },
   
    {
      name: 'Kanakarasu Manickam',
      position: 'Tech Lead',
      imageUrl: removebgKanakarasu,
      color: '#aaa9e8',
      about: 'Kanakarasu is the Tech Lead and has led multiple successful software projects.',
      linkedin: 'https://www.linkedin.com/in/kanakarasu-manickam-6089b025/'
    },
    {
      name: 'Varshasri',
      position: 'Senior Software Engineer',
      imageUrl: removebgVarshasri,
      color: '#f9c8d1',
      about: 'Varshasri is a Senior Software Engineer with over 5 years of experience in software development.',
      linkedin: 'http://www.linkedin.com/in/varsha-sri-bb4034146'
    },
    {
      name: 'Rupavarshni',
      position: 'Senior Software Engineer',
      imageUrl: removebgRupavarshini,
      color: '#ffe1ae',
      about: 'Rupavarshni is a senior engineer with expertise in building scalable web applications.',
      linkedin: 'http://www.linkedin.com/in/rupa-varshni'
    },
    {
      name: 'Rajappan',
      position: 'Software Engineer',
      imageUrl: removebgRajappan,
      color: '#a8e0f4',
      about: 'Rajappan is a Software Engineer passionate about coding and solving complex problems.',
      linkedin: 'http://www.linkedin.com/in/rajappan-sellamuthu-5742b2204'
    },
    {
      name: 'Sarathi',
      position: 'Tech Lead',
      imageUrl: removebgSarathi,
      color: '#e1f1c7',
      about: 'Sarathi is a Tech Lead with extensive experience in leading agile development teams.',
      linkedin: 'https://www.linkedin.com/in/sarathi'
    },
    // {
    //   name: 'Rajeshwari',
    //   position: 'Tech Lead',
    //   imageUrl: removebgVarshasri,
    //   color: '#f9c8d1',
    //   about: 'Rajeshwari has a deep understanding of modern software architecture and leads multiple tech teams.',
    //   linkedin: 'https://www.linkedin.com/in/rajeshwari'
    // },
  ];

  const hrMembers = [
    {
      name: 'Esther Jentia',
      position: 'Head of the Department of Human Resources Management',
      imageUrl: removebgEsther,
      color: '#bbe8cd',
      about: 'Esther is the Human Resources Management  and has led multiple successful HR candidates.',
      linkedin: 'http://www.linkedin.com/in/esther-jenita'
    },
    {
      name: 'Soundarya',
      position: 'Senior Human Resource(HR)',
      imageUrl: removebgSoundharya,
      color: 'pink',
      about: 'Soundarya is the Senior HR manager and brings a wealth of experience in HR management.',
      linkedin: 'https://www.linkedin.com/in/soundarya'
    },
    {
      name: 'Rekha',
      position: 'Human Resource(HR)',
      imageUrl: removebgRekha,
      color: 'lightblue',
      about: 'Rekha is an HR expert who manages the company’s recruitment and employee engagement.',
      linkedin: 'https://www.linkedin.com/in/rekha'
    },
    // {
    //   name: 'Sumi',
    //   position: 'Business Development Manager(BDM)',
    //   imageUrl: removebgVarshasri,
    //   color: 'lightgreen',
    //   about: 'Sumi is the Business Development Manager with expertise in expanding business operations.',
    //   linkedin: 'https://www.linkedin.com/in/sumi'
    // },
    // {
    //   name: 'Darshni',
    //   position: 'Business Development Manager(BDM)',
    //   imageUrl: rupavarshini,
    //   color: 'lightcoral',
    //   about: 'Darshni is responsible for building business relationships and expanding the market.',
    //   linkedin: 'https://www.linkedin.com/in/darshni'
    // },
  ];

  return (
    <Container>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 mb-6">Product Team</h2>
      <div className="flex flex-wrap justify-center gap-8 py-16">
        {teamMembers.map((member, index) => (
          <LeadershipCard
            key={index}
            name={member.name}
            position={member.position}
            imageUrl={member.imageUrl}
            color={member.color}
            onReadMore={() => handleReadMore(member)} // Pass function to open popup
          />
        ))}

        {/* Popup Modal */}
        {selectedMember && <LeadershipPopup member={selectedMember} closePopup={closePopup} />}
      </div>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 my-6">HR and Management</h2>
      <div className="flex flex-wrap justify-center gap-8 py-16 bg-gray-100">
        {hrMembers.map((member, index) => (
          <LeadershipCard
            key={index}
            name={member.name}
            position={member.position}
            imageUrl={member.imageUrl}
            color={member.color}
            onReadMore={() => handleReadMore(member)} // Pass function to open popup
          />
        ))}

        {/* Popup Modal */}
        {selectedMember && <LeadershipPopup member={selectedMember} closePopup={closePopup} />}
      </div>
    </Container>
  );
};

export default ExecutiveTeam;
