import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../contexts/StateContext";
import toast from "react-hot-toast";
import { useNavigate, NavLink } from "react-router-dom";
import { payViaGateWayBackend } from "../../../api/commonApi";
import { useLocation } from "react-router-dom";
import {
  FINANCIER_TOKEN,
  ACCESS_TOKEN,
  transactionTrackingIdCons,
  delimiter,
  delimiterUi,
} from "../../../config/Config";
import { useAuth } from "../../../contexts/AuthContext";
const PaymentGateway = ({}) => {
  const { authData } = useAuth();
  const { token, transactionTrackingId } = authData;
  const location = useLocation();
  const { subscription } = location.state;
  const navigate = useNavigate();

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const [transactionRequest, setTransactionRequest] = useState({
    subscriptionId: "",
    paymentId: "",
    amount: "",
    email: "",
    costIncludingGstPay: "",
    conExcludingGstPay: "",
    subscriptionFor: "",
    token:""
  });

  const [showLoading, setShowLoading] = useState(false);
  const [customPayAmount, setCustomPayAmount] = useState("");

  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );

  useEffect(() => {
    document.title = "Payment Gateway";
    updateTransactionRequestFromSubscription();
    setShowLoading(false);
  }, []);

  const updateTransactionRequestFromSubscription = () => {
    localStorage.setItem(
      transactionTrackingIdCons,
      subscription.subscriptionId +
        delimiter +
        subscription.paymentId +
        delimiter +
        subscription.userName
    );
   // alert(
     // "update in local storage" +
      //  localStorage.getItem(transactionTrackingIdCons)
   // );
    //transactionTrackingId=localStorage.getItem(transactionTrackingId);
    //alert("update in local storage"+transactionTrackingId);
    let req = { ...transactionRequest };
    req.paymentId = subscription.paymentId;
    req.subscriptionId = subscription.subscriptionId;
    req.email = subscription.userName;
    req.costIncludingGstPay = subscription.costIncludingGstPay;
    req.conExcludingGstPay = subscription.costExcludingGstPay;
    req.subscriptionFor = subscription.subscriptionFor;
    req.amount = subscription.costIncludingGstPay;
    req.token= localStorage.getItem(ACCESS_TOKEN);
    setTransactionRequest(req);
    setShowLoading(false);
  };

  const paymentGateWayViaBackend = (event) => {
    event.preventDefault();

    setShowLoading(true);

    const amountToPay = customPayAmount
      ? customPayAmount
      : transactionRequest.amount;

    const updatedTransactionRequest = {
      ...transactionRequest,
      amount: amountToPay,
    };

    payViaGateWayBackend(updatedTransactionRequest)
      .then((response) => {
        let res = response.data;
        let data = res.data;
        //alert(
          //"local storage before redirect after backend response" +
            //localStorage.getItem(transactionTrackingIdCons)
        //);
       // const redirectInfo = data.data.instrumentResponse.redirectInfo;
        //const token = localStorage.getItem("accessToken");
        //alert("Before param" + localStorage.getItem(transactionTrackingIdCons));
        //const accessToken = localStorage.getItem("ACCESS_TOKEN");
        //const transactionTrackingId = localStorage.getItem(
          //"transactionTrackingIdCons"
        //);
        //const combinedParams = `${localStorage.getItem(
          //"ACCESS_TOKEN"
        //)}${delimiterUi}${localStorage.getItem("transactionTrackingIdCons")}`;
        // Now you can use this combinedParams as a single path parameter
        //const url = `https://example.com/path/${combinedParams}`;

        // Construct the URL with multiple query parameters
        //const params = new URLSearchParams({
        // accessToken: localStorage.getItem(ACCESS_TOKEN),
        // transactionTrackingId: localStorage.getItem(transactionTrackingIdCons),
        // });
        // Redirect to payment gateway with the constructed URL
        //alert("params" + JSON.stringify(combinedParams));
       // const redirectUrl = `${redirectInfo.url}?${combinedParams}`;
        const redirectInfo = data.data.instrumentResponse.redirectInfo.url;
        //+`?params=${combinedParams}`;
        //alert("before Redirec" + JSON.stringify(redirectInfo));
        window.location.href =redirectInfo;
        //const token = localStorage.getItem("accessToken");
     

        
        
       // window.location.href = `${redirectInfo.url}?params=${params}`;
        
        //const url = new URL(redirectInfo);

        // Add the additional parameter
        //url.searchParams.append("myParam", combinedParams);
//alert(url.to)
        // Construct the new URL
        //const redirectUrl = url.toString();


        // Redirect to the new URL
//        window.location.href = redirectUrl;
        //const newUrl = `${redirectInfo.url}/${combinedParams}`;
        //  
        //        const redirectUrl = `${redirectInfo.url}#myParam=${combinedParams}`;
        //      window.location.href = redirectUrl;
        // window.location.href = `${redirectInfo.url}?${params.toString()}`;
        //window.location.href =redirectInfo
        //.url+`?params=${params}`;
       
        setShowLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setShowLoading(false);
      });
  };

  return (
    <div className="mx-auto p-5 max-w-screen-sm">
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md"
        onClick={() => navigate("/traineeData")} 
      >
        Back
      </button>

      <div className="bg-yellow-200 p-5 mt-5 rounded-md text-center">
        <h1
          className="mb-2 font-semibold text-xl"
          style={{ color: currentColor }}
        >
          Payment Status
        </h1>
        <p className="text-gray-600">
          Paid So Far: {"\u20A8"} {subscription.paidSoFar} /-
        </p>
        <p className="text-gray-600">
          Remaining Amount: {"\u20A8"}{" "}
          {(transactionRequest.costIncludingGstPay || 0) -
      (subscription.paidSoFar || 0)} /-
        </p>
      </div>
      <div className="bg-blue-200 p-5 rounded-md text-center">
        <form onSubmit={paymentGateWayViaBackend}>
          <img
            src={require("../../../assets/images/careers/phoneph.png")}
            className="rounded-circle mx-auto mb-2"
            alt="PhonePe Logo"
          />
          <span className="block mb-4 text-sm">
            Via PhonePe Payment Gateway
          </span>

          <input
            type="number"
            placeholder="Enter custom pay amount"
            className="w-full p-2 mb-2 rounded-md"
            value={customPayAmount}
            onChange={(e) => {
              const value = e.target.value;
              if (
                !value.includes("-") &&
                (parseFloat(value) > 0 || value === "")
              ) {
                setCustomPayAmount(value);
              }
            }}
          />

          <button
            type="submit"
            className="w-full mt-4 p-2 bg-black text-white rounded-md"
          >
            Pay {"\u20A8"}{" "}
            {customPayAmount && !isNaN(customPayAmount)
              ? customPayAmount
              : Math.max(
                  transactionRequest.costIncludingGstPay - (subscription?.paidSoFar || 0),
                  0 // Ensure the amount doesn't go negative
                )}
            /-
          </button>
        </form>
      </div>
      <h1
        className="mb-2 font-semibold text-xl text-center"
        style={{ color: currentColor }}
      >
        OR
      </h1>
      <div className="bg-green-200 p-5 mt-5 rounded-md text-center">
        <h1
          className="mb-2 font-semibold text-xl"
          style={{ color: currentColor }}
        >
          Pay via: HDFC Bank
        </h1>
        <ul className="text-gray-600 font-light">
          <li>A/c no: 50200084294942</li>
          <li>IFSC Code: HDFC0002222</li>
        </ul>
        <p className="mt-2 text-gray-600 text-sm">
          For NEFT/IMPS bank transfer, please send an email to{" "}
          <span className="text-blue-500">business@yaazhtech.com</span> for
          payment status update manually.
        </p>
      </div>
      <h1
        className="mb-2 font-semibold text-xl text-center"
        style={{ color: currentColor }}
      >
        OR
      </h1>
      <div className="bg-yellow-200 p-5 rounded-md text-center">
        <h1
          className="mb-2 font-semibold text-xl"
          style={{ color: currentColor }}
        >
          Pay via: GPay/PhonePe
        </h1>
        <p className="text-gray-600 font-light">
          Use mobile number +91 6381699905 for GPay or PhonePe payments. please
          send an email to{" "}
          <span className="text-blue-500">business@yaazhtech.com</span> for
          payment status update manually.
        </p>
      </div>

      {/*<div className="bg-gray-200 p-5 mb-5 rounded-md text-center">
        <h1 className="mb-2 font-semibold text-xl" style={{ color: currentColor }}>
          Payment Summary
        </h1>
        <ul className="text-gray-600 font-light">
          <li>Payee username: {transactionRequest.email}</li>
          <li>Subscription For: {transactionRequest.subscriptionFor}</li>
          <li>Subscription Id: {transactionRequest.subscriptionId}</li>
          <li>Amount ExcludingGstPay : {transactionRequest.conExcludingGstPay} </li>
          <li>Amount IncludingGstPay: {transactionRequest.costIncludingGstPay} </li>
        </ul>
        <div className="mt-2 text-gray-600">
          <span className="font-semibold">
            Total to pay: {'\u20A8'} {transactionRequest.costIncludingGstPay}/-
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default PaymentGateway;
