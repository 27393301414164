import React, { useState } from "react";
import { FaGlobe } from "react-icons/fa";
import Flag from "react-flagkit";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Using useContextSelector to access specific parts of the context
  const language = useContextSelector(StateContext, (state) => state.language);
  const setLanguage = useContextSelector(
    StateContext,
    (state) => state.setLanguage
  );

  const languages = [
    { name: "English", abbreviation: "EN", flag: "GB" },
    { name: "Tamil", abbreviation: "TN", flag: "IN" },
    { name: "Deutsch", abbreviation: "DE", flag: "DE" },
    { name: "Chinese", abbreviation: "CN", flag: "CN" },
    { name: "Japanese", abbreviation: "JP", flag: "JP" },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (abbreviation) => {
    setLanguage(abbreviation);
    setIsOpen(false);
  };

  const selectedFlag = languages.find(
    (lang) => lang.abbreviation === language
  )?.flag;

  return (
    <div className="relative inline-block md:mx-0 mx-1 text-left">
      <button
        onClick={toggleDropdown}
        className="flex items-center px-4 py-2 md:text-lg text-base"
      >
        {selectedFlag && (
          <Flag
            country={selectedFlag}
            className="md:ml-2"
            style={{ width: 20, height: 14 }}
          />
        )}
        <span className="md:ml-2">{language}</span>
        <span className="md:ml-2">
          <FaGlobe />
        </span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {languages.map((language) => (
              <div
                key={language.abbreviation}
                onClick={() => handleLanguageSelect(language.abbreviation)}
                className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
              >
                <Flag
                  country={language.flag}
                  className="mr-2"
                  style={{ width: 20, height: 14 }}
                />
                {language.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
