import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./NavLinks";
import LanguageSelector from "./LanguageSelector";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import { yaazhtechLogo } from "../assets/images";
import { ACCESS_TOKEN, ADMIN_TOKEN, HR_TOKEN, CLIENT_TOKEN, FINANCIER_TOKEN } from "../config/Config";

const Navigation = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    localStorage.removeItem(HR_TOKEN);
    localStorage.removeItem(EMPLOYEE_TOKEN);
    localStorage.removeItem(CLIENT_TOKEN);
    localStorage.removeItem(FINANCIER_TOKEN);
    setIsLoggedIn(false);
    navigate("/");
  };
  const handleActionClick = () => {
    setOpen(false);
  };

  const formatDate = (date) => date.toLocaleDateString();
  const formatTime = (time) => time.toLocaleTimeString();

  return (
    <nav className="p-4">
  <div className="container mx-auto flex justify-between items-center">
    {/* Logo and Time Section */}
    <div className="flex items-center space-x-4">
      <div className="flex items-center space-x-2">
        <img
          src={yaazhtechLogo}
          alt="Yaazhtech Logo"
          className="md:w-12 md:h-12 w-10 h-10 object-contain"
        />
        <div>
          <h1 className="text-lg md:text-xl font-semibold text-black">
            Yaazhtech
          </h1>
          <p className="text-[8px] md:text-sm text-purple-400 bg-clip-text bg-gradient-to-r from-blue-400 via-pink-500 to-purple-500">
            Teamwork Makes Dream Work
          </p>
        </div>
      </div>
      <div className="md:flex flex-col text-[8px] md:text-sm text-black">
        <p>G: {formatDate(currentTime)}</p>
        <p>T: {formatTime(currentTime)}</p>
      </div>
      <div className="md:hidden flex-col text-xs md:text-sm">
      <LanguageSelector />
      </div>
    </div>

    {/* Desktop Navigation */}
    <ul className="hidden md:flex items-center space-x-6">
      <NavLinks actionClick={handleActionClick} />
      <li>
        <Link to="/" className=" flex justify-between md:text-dark text-lg items-center ml-2 md:pr-0 pr-5 group">
          Pricing
        </Link>
      </li>
      <li>
        <Link
          to={isLoggedIn ? "#" : "/login"}
          onClick={isLoggedIn ? handleLogout : null}
          className="flex justify-between md:text-dark text-lg items-center ml-2 md:pr-0 pr-5 group"
        >
          {isLoggedIn ? "Logout" : "Login"}
        </Link>
      </li>
      <LanguageSelector />
    </ul>

    {/* Mobile Menu Toggle */}
    <button className="md:hidden text-xl md:text-2xl" onClick={() => setOpen(!open)}>
      <ion-icon name={open ? "close" : "menu"}></ion-icon>
    </button>
  </div>

  {/* Mobile Navigation */}
  <ul
    className={`fixed top-0 left-0 w-full h-full overflow-y-auto bg-white transform ${
      open ? "translate-x-0" : "-translate-x-full"
    } transition-transform duration-300 z-50`}
  >
    <div className="flex justify-between items-center p-4">
      <img src={yaazhtechLogo} alt="Yaazhtech Logo" className="w-10 h-10" />
      <button className="text-xl" onClick={() => setOpen(false)}>
        <ion-icon name="close"></ion-icon>
      </button>
    </div>
    <div className="flex flex-col space-y-4 p-6">
      <NavLinks actionClick={handleActionClick} />
      <li>
        <Link
          to="/"
          onClick={() => setOpen(false)}
          className=" py-1 flex justify-between md:text-dark text-lg items-center ml-2 md:pr-0 pr-5 group"
        >
          Pricing
        </Link>
      </li>
      <li>
        <Link
          to={isLoggedIn ? "#" : "/login"}
          onClick={() => {
            if (isLoggedIn) handleLogout();
            setOpen(false);
          }}
          className="py-1 flex justify-between md:text-dark text-lg items-center md:pr-0 pr-5 group ml-2"
        >
          {isLoggedIn ? "Logout" : "Login"}
        </Link>
      </li>
    </div>
  </ul>
</nav>

  );
};

export default Navigation;
