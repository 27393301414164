import React, { useState } from 'react';
import { kannaiyan, kanakarsu, removebgKannaiyan, removebgKanakarasu } from '../../../assets/images';

const BoardOfDirectors = () => {
  const [selectedDirector, setSelectedDirector] = useState(null);
  const directors = [
    {
      name: 'Kannaiyan',
      position: 'Executive Chairman',
      imageUrl: removebgKannaiyan,
      linkedin: 'http://www.linkedin.com/in/kannaiyan-s',
      about: 'Kanniyan is an experienced leader with a focus on innovation and strategy.',
      color: 'lightcoral',
    },
    // {
    //   name: 'Kanakarasu Manickam',
    //   position: 'Independent Director',
    //   imageUrl: removebgKanakarasu,
    //   linkedin: 'https://www.linkedin.com/in/kanakarasu-manickam-6089b025/',
    //   about: 'Kanakarasu brings decades of experience in financial management and governance.',
    //   color: 'lightblue',
    // },
    // Add more directors
  ];

  const closeModal = () => setSelectedDirector(null);

  return (
    <div className="h-[650px] py-16 bg-white">
      <h2 className="text-3xl text-center font-bold mb-12">Executive Members</h2>
      
      {/* Responsive director cards */}
      <div className="flex justify-center items-center h-screen">
      <div className="w-[342px] flex flex-wrap justify-center items-center p-4 rounded-lg shadow-lg mt-[-300px]">
        {directors.map((director, index) => (
          <div
            key={index}
            className="w-full sm:w-[342px]  bg-gray-50 shadow-md rounded-lg overflow-hidden transition transform hover:scale-105"
          >
            <div 
              className="w-full h-[300px] flex justify-center items-center"
              style={{ backgroundColor: director.color }}
            >
              <img src={director.imageUrl} alt={director.name} className="w-[250px] h-[300px] object-cover" />
            </div>
            <div className="p-4 text-left bg-white">
              <h3 className="text-gray-900 font-bold text-xl">{director.name}</h3>
              <p className="text-gray-600">{director.position}</p>
              <div className="mt-4">
                <button
                  onClick={() => setSelectedDirector(director)}
                  className="flex items-center text-primary-500 hover:text-pink-700"
                >
                  Read more
                  <span className="ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      {/* Modal Popup */}
      {selectedDirector && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-[90%] max-w-4xl rounded-lg p-8 relative">
            {/* Close button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
    
            {/* Popup content */}
            <div className="flex flex-col md:flex-row gap-6">
              {/* Left side: Image with background color */}
              <div
                className="w-full md:w-1/2 flex justify-center items-center"
                style={{ backgroundColor: selectedDirector.color }}
              >
                <img src={selectedDirector.imageUrl} alt={selectedDirector.name} className="w-[250px] h-[300px] object-cover" />
              </div>
    
              {/* Right side: Details */}
              <div className="w-full md:w-1/2 flex flex-col justify-center">
                <h2 className="text-2xl font-bold text-gray-900">{selectedDirector.name}</h2>
                <p className="text-xl text-gray-600 mb-4">{selectedDirector.position}</p>
                <p className="text-gray-700 mb-4">{selectedDirector.about}</p>
    
                {/* LinkedIn link */}
                {selectedDirector.linkedin && (
                  <a
                    href={selectedDirector.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pink-500 hover:text-pink-800"
                  >
                    View on LinkedIn
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardOfDirectors;